<template>
  <div>
    <svg
      width="14px"
      height="11px"
      viewBox="0 0 14 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      @mouseover="fillColor"
      @mouseleave="removeColor"
    >
      <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
      <title></title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        :opacity="opacity"
      >
        <g
          id="Sub-profiles"
          transform="translate(-1381.000000, -452.000000)"
          :fill="fill"
          fill-rule="nonzero"
        >
          <g id="Notification" transform="translate(1187.500000, 50.000000)">
            <g id="Group-4">
              <g id="Group-3" transform="translate(0.000000, 222.000000)">
                <g id="Stacked-Group" transform="translate(31.000000, 34.000000)">
                  <g id="Group-6" transform="translate(0.000000, 138.000000)">
                    <g id="Switch" transform="translate(162.928571, 8.428571)">
                      <g id="swap">
                        <polygon
                          id="Path"
                          points="2.91299556 4.40327067 0 7.32355556 2.91299556 10.2438404 2.91299556 8.05361822 8.03082622 8.05361822 8.03082622 6.59349289 2.91299556 6.59349289"
                        />
                        <polygon
                          id="Path"
                          points="13.1413333 2.94311111 10.2283378 0.0228262222 10.2283378 2.21304844 5.11050711 2.21304844 5.11050711 3.67317378 10.2283378 3.67317378 10.2283378 5.863396"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fill: "#EFEFF4",
      opacity: "0.5"
    };
  },
  methods: {
    fillColor() {
      this.fill = "#ff9600";
      this.opacity = "1";
    },
    removeColor() {
      this.fill = "#EFEFF4";
      this.opacity = "0.5";
    }
  }
};
</script>

<style lang="scss" scoped>
</style>